import axios from "axios";

export default axios.create({
  baseURL: "https://backend-owner.egmokka.com",
  headers: {
  	// "Authorization": localStorage.getItem('Token'),
    'Authorization': 'Bearer 1+1',
    "Content-Type": "multipart/form-data"
  }
});
